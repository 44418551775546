<template>
  <h1 class="text-center">Order History Pages</h1>

</template>

<script>
export default {
  name: "OrderHistoryPages"
}
</script>

<style scoped>

</style>